import React, { Component } from "react";
import ServiceOne from "../../elements/service/ServiceOne";
import VideoModal from "../../blocks/TSVideo";

class SliderTSMain extends Component {
  render() {
    return (
        <div className="slider-activation">
            {/* Start Single Slide */}
            <div className="slide slide-style-1 ts_main_slider d-flex align-items-center bg_image bg_image--1" data-black-overlay="6">
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
								
                                <h1 className="title uppercase theme-gradient ts_slider_colorshifter">Your Digital Partner</h1>
                                <h3 className="title uppercase theme-gradient">The creativity of a small team of experts<br/><span className="">The stability of decades of experience</span></h3>
                            </div>
                        </div>
                    </div>
					
					{/* Start Video Area  */}
					<div className="container ">
							<div className="row sercice-details-content ts_main_slider_video align-items-center">
							<div className="col-lg-12 ts_main_slider_video_column">
								<div className="thumb ts_showyimage position-relative">
									<img className="w-100" src={process.env.PUBLIC_URL + "/assets/images/ts/videobanner.jpg"} alt="Service Images"/>
									<VideoModal />
								</div>
								<p className="ts_slider_colorshifter ts_video_subtitle">Check out this short video to see an example of how SAP Business One and a partnership with Tiger Sheep has boosted the business of one of our exceptional clients, Blue Marble Communications</p>
							</div>
						</div>
					</div>
					{/* End Video Area  */}
					
                    {/* Start Service Area */}
                    <div className="service-wrapper service-white">
                        <ServiceOne />
                    </div>
                    {/* End Service Area */}
                </div>
            </div> 
            {/* End Single Slide */}
        </div>
    );
  }
}
export default SliderTSMain;