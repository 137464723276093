import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import SliderOne from "../../component/slider/SliderOne";
import ServiceTwo from "../../elements/service/ServiceTwo";
import CounterOne from "../../elements/counters/CounterOne";
import Testimonial from "../../elements/Testimonial";
import About from "../../component/HomeLayout/homeOne/About";
import Portfolio from "../../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../../elements/blog/BlogContent";
import BrandTwo from "../../elements/BrandTwo";
import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../../elements/common/Breadcrumb";

class ChangeMe extends Component{
    render(){
        const PostList = BlogContent.slice(0 , 3);
		let title = 'Work Examples',
        description = 'This is a Our Work paragraph! Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet';
        return(
            <Fragment> 
                
                <PageHelmet pageTitle='Work Examples' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Work Examples'}   />
                {/* End Breadcrump Area */}
                
                {/* Start About Area */}
                <div className="about-area about-position-top pb--120">
                    <div className="about-wrapper">
						<div className="container">
						
						
							<div id="ecommerce" className="ts_list_style row row--35 align-items-center">

								<div className="col-lg-8 col-md-12">
									<div className="about-inner inner">
										<div className="section-title">
											<h2 className="title">eCommerce Site</h2>
											<p className="description">
												<p>A large parts manufacturer wanted to upgrade their web store from a limited catalog to a full-featured eCommerce site. Tiger Sheep worked closely to sort their massive product line into a clean category system, and used the excellent Magento platform to build a gorgeous branded solution. Custom integrations were built to pull current inventory directly from their ERP suite, and to write purchases directly back. Their whole catalog is now available for online perusal, with accurate inventory insight and a wide array of great features. </p>
											</p>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>
													<h3 className="title">Application Benefits:</h3>
													<ul>
														<li>Purchases write back directly to their business software.</li>
														<li>Inventory levels are updated live, and purchases update both the eCommerce platform and the backend software.</li>
														<li>A wide array of payment methods are enabled, enabling users more choice.</li>
														<li>Automation decreases human entry error.</li>
														<li>Billing is integrated, removing a large double-entry step</li>
													</ul>
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-12">
									<div className="">
										<img className="w-100 ts_showyimage" src={process.env.PUBLIC_URL + "/assets/images/ts/workexample_warehouse.jpg"} alt="About Images"/>
									</div>
								</div>
							</div>
							
							<div id="pos" className="ts_list_style row row--35 align-items-center">
								<div className="col-lg-8 col-md-12">
									<div className="about-inner inner">
										<div className="section-title">
											<h2 className="title">Point-of-Sale (POS) Application</h2>
											<p className="description">
												<p>A large client recently upgraded from an MSDOS-based system to a modern ERP solution, and found that there were a ton of holes requiring them to alter and retrain processes that had been fast and functional for decades. Tiger Sheep was first brought on as an ERP consultant, and quickly realized that a custom POS application would provide a huge amount of benefit to the client. 90% of employees interact with the ERP software for entering in work orders, so a custom frontend application was built to allow employees to enter work orders faster and smarter.</p>
											</p>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>
													<h3 className="title">Application Benefits:</h3>
													<ul>
														<li>Application-entered work orders write back to native ERP data, so no process change is required of admins and accountants.</li>
														<li>Entry is agnostic to the speed of the ERP solution's native interface, so entry stays speedy.</li>
														<li>Requires very little application-specific training, as the process defines the application. Before, the user was forced to bend their onboarding around the ERP software used.</li>
														<li>Order entry follows the 'script' the company has used for years when customer onboarding, so no unnatural software holds are forced on the user.</li>
														<li>Custom application development enables a huge amount of branding, so this application 'feels' like a company application. Colors, logos, and style guides define the design of the application.</li>
														<li>Changes can be built and added asynchronously and tested separately, to build future features in without interrupting the current flow.</li>
														<li>Reporting remains unhindered, and old data is simple to migrate into the new system.</li>
													</ul>
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-12">
									<div className="">
										<img className="w-100 ts_showyimage" src={process.env.PUBLIC_URL + "/assets/images/ts/workexample_sale.jpg"} alt="About Images"/>
									</div>
								</div>
							</div>
							
							
							<div id="sales" className="ts_list_style row row--35 align-items-center">

								<div className="col-lg-8 col-md-12">
									<div className="about-inner inner">
										<div className="section-title">
											<h2 className="title">Call Center Sales Application</h2>
											<p className="description">
												<p>A client was encountering significant slowdowns with a large slice of their workforce. Call center sales positions were proving to be high turnover, so training these nontechnical staffers in complex ERP software was a significant hindrance. Tiger Sheep charted out all the functions that the call center was using inside the client's ERP software, and mapped this into a single simple-to-use application. Training time, call time, and entry time were all significantly improved, and the client has seen a huge increase in sales revenue.</p>
											</p>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>
													<h3 className="title">Application Benefits:</h3>
													<ul>
														<li>Training time was signficantly reduced, allowing initial employee onboarding to progress much faster.</li>
														<li>Advanced call list features and filters were enabled, allowing call center staffers to access relevant information faster and easier.</li>
														<li>Average call time decreased by ~30%.</li>
														<li>Post-call entry time was significantly sped up.</li>
														<li>Administration and accounting see only native ERP documents, as if nothing changed.</li>
														<li>Application more closely follows the 'call script', to avoid technology slowing down calls.</li>
														<li>Custom application development enables a huge amount of branding, so this application 'feels' like a company application. Colors, logos, and style guides define the design of the application.</li>
														<li>Changes can be built and added asynchronously and tested separately, to build future features in without interrupting the current flow.</li>
														<li>Reporting remains unhindered, and old data is simple to migrate into the new system.</li>
													</ul>
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-12">
									<div className="">
										<img className="w-100 ts_showyimage" src={process.env.PUBLIC_URL + "/assets/images/ts/workexample_call.jpg"} alt="About Images"/>
									</div>
								</div>
							</div>
							
							<div id="technician" className="ts_list_style row row--35 align-items-center">
								<div className="col-lg-8 col-md-12">
									<div className="about-inner inner">
										<div className="section-title">
											<h2 className="title">Technician Work Order Application</h2>
											<p className="description">
												<p>Skilled technicians in a large repair organization were hitting a significant slowdown with activity entry. Their entry method did not allow intelligent changes, or job-specific decisions, so every order required quite a bit of manual data entry. To ease this waste of productivity, Tiger Sheep worked closely with these individuals to build an activity entry application that allowed them to quickly and accurately state work done, down to individual screws. New technician onboarding time was significantly reduced, and employee job satisfaction was boosted.</p>
											</p>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>
													<h3 className="title">Application Benefits:</h3>
													<ul>
														<li>Entry regions are editable by the user, to enable custom tuning without a support call.</li>
														<li>Often-used activity choices are highlighted for simplified entry, while retaining the ability to enter infrequently used items. </li>
														<li>Administration is able to 'require' specific topics to motivate complete entry writing.</li>
														<li>Average activity entry time was halved.</li>
														<li>Employee satisfaction was significantly increased.</li>
														<li>Custom application development enables a huge amount of branding, so this application 'feels' like a company application. Colors, logos, and style guides define the design of the application.</li>
														<li>Changes can be built and added asynchronously and tested separately, to build future features in without interrupting the current flow.</li>
														<li>Reporting remains unhindered, and old data is simple to migrate into the new system.</li>
													</ul>
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-12">
									<div className="">
										<img className="w-100 ts_showyimage" src={process.env.PUBLIC_URL + "/assets/images/ts/workexample_tech.jpg"} alt="About Images"/>
									</div>
								</div>
							</div>
							
							
							
						</div>
					</div>
                </div>
                {/* End About Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
            </Fragment>
        )
    }
}
export default ChangeMe;