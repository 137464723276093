import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Direct Business Support',
        description: 'Responsive support from a skilled team of experts in SAP Business One and general business intelligence'
    },
    {
        icon: '02',
        title: 'Customized Solutions',
        description: 'A massive library of client-customized projects to solve a wide variety of complicated process issues'
    },
    {
        icon: '03',
        title: 'Reporting & Development',
        description: "Beautiful custom reports and portals give a level of insight into your business you've only dreamed about"
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img className="image_themer" src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;