import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import SliderTSMain from "../component/slider/SliderTSMain";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterTSMain from "../elements/counters/CounterTSMain";
import Testimonial from "../elements/Testimonial";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'SAP Business One Support',
        description: 'Tiger Sheep is a nationally recognized support expert in SAP Business One'
    },
    {
        icon: <FiLayers />,
        title: 'Custom work order entry solutions',
        description: 'Finely-tuned work order entry gateways built with speed of entry as the primary goal'
    },
    {
        icon: <FiUsers />,
        title: 'Full sales workflow replacements',
        description: 'Permission-based call center sales workflows, built to simplify training and employee-specific information access'
    },
    {
        icon: <FiMonitor />,
        title: 'Logical automation',
        description: 'A huge amount of software automations with features like logic-triggered mailings, load tracking, invoicing, and more'
    },
    {
        icon: <FiCast />,
        title: 'Third-party software integrations',
        description: 'Interfaces and automation built around industry-required software to augment and simplify existing processes'
    },
    {
        icon: <FiMonitor />,
        title: 'Custom point-of-sale workflows',
        description: 'Question-based POS entry, used by both CSRs and sales staff on phones, tablets, and desktops'
    },
]

class MainDemo extends Component{
    render(){
        const PostList = BlogContent.slice(0 , 3);
        return(
            <Fragment> 
                <Helmet pageTitle="Home Page" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <SliderTSMain />
                </div>
                {/* End Slider Area   */}
                
                {/* Start About Area */}
                <div className="about-area about-position-top pb--120">
                    <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail ts_thumb_lower ts_showyimage">
                                    <img className="w-100" src={process.env.PUBLIC_URL + "/assets/images/ts/hand-device.jpg"} alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
												<p>Every business has different needs, and software often doesn't match your requirements exactly. That's where Tiger Sheep comes in. By augmenting your current processes or building a custom solution from scratch, Tiger Sheep's specialty is process-focused software solutions custom-built to perfection. Through gorgeously-designed custom applications, robust automation, and intelligent data decisions, we elevate your business to the next level! </p>
												<p>Our experts draw from different backgrounds and bring decades of business software expertise to the table. We focus our work on modern solutions, using technology to bring your process to any device. Custom software, custom websites, mobile applications... we take your business goals and give you the solution that lets your business flourish.</p>
                                                <h4 className="title">Don't let old technology drag your company down.</h4>
                                                <p>Good software enables good business! Are you stuck using an industry solution that only gets you close to what you need? Are you still using software built in the MS-DOS days? It's time for an upgrade! Tiger Sheep has hundreds of happy customers using our solutions daily, and our clients are secure in the fact that our applications merge great design with smart data policy.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                {/* End About Area */}
				
				{/* Start ServiceOriginal Area
                <div className="service-area ptb--80  bg_image bg_image--3">
                   <div className="container">
                        <ServiceTwo />
                   </div>
                </div>
				End ServiceOriginal Area */}
				
                {/* Start Portfolio Area
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Portfolio />
                    </div>
                </div>
                End Portfolio Area */}


                {/* Start CounterUp Area */}
                <div className="rn-counterup-area bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
									<br/>
									<br/>
									<br/>
									<br/>
                                    <h3 className="fontWeight500">Quick Facts</h3>
                                </div>
                            </div>
                        </div>
                        <CounterTSMain />
						<br/>
						<br/>
						<br/>
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Testimonial Area
                <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                End Testimonial Area */}

                {/* Start Blog Area
                <div className="rn-blog-area pt--120 bg_color--1 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-left">
                                    <h2>Latest News</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                                    <a className="btn-transparent rn-btn-dark" href={process.env.PUBLIC_URL + "/blog"}><span className="text">View All News</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60 mt_sm--40">
                            {PostList.map((value , i ) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            <a href={process.env.PUBLIC_URL + "/blog-details"}>
                                                <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>
                                            </a>
                                        </div>
                                        <div className="content">
                                            <p className="blogtype">{value.category}</p>
                                            <h4 className="title"><a href={process.env.PUBLIC_URL + "/blog-details"}>{value.title}</a></h4>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" href={process.env.PUBLIC_URL + "/blog-details"}>Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>    
                    </div>    
                </div>
                End Blog Area */}

				{/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Some of Our Client Partnerships</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Brand Area
                <div className="rn-brand-area brand-separation bg_color--5 pb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
            </Fragment>
        )
    }
}
export default MainDemo;