import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import GoogleMapReact from 'google-map-react';
import ContactTwo from "../../elements/contact/ContactTwo";
import BrandTwo from "../../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

const AnyReactComponent = ({ text }) => <div className="ts_map_icon"><p>{text}</p></div>;

class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 45.4868976,
            lng: -122.8209082
        },
        zoom: 13,
		scrollwheel: false,
		gestureHandling: 'greedy',
		options: {
			gestureHandling: 'greedy',
			scrollwheel: false,
		}
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190 bg_image ts_bg_image_contact"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient ts_slider_colorshifter">Contact Us Today</h2>
                           
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div>
                {/* End Contact Page Area  */}
                {/* Start Contact Map  */}
                <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '350px', width: '100%' }}>
                        <GoogleMapReact
							bootstrapURLKeys={{ key: "AIzaSyDsFycEdfyBd8z4JojKjv8TKHOon0_Hssc" }}
							defaultCenter={this.props.center}
							defaultZoom={this.props.zoom}
							yesIWantToUseGoogleMapApiInternals
							>
							<AnyReactComponent
								lat={45.4868976}
								lng={-122.8209082}
								text="Tiger Sheep"
							/>
                        </GoogleMapReact>
                    </div>
                </div>
                {/* End Contact Map  */}
                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120">
                    <div className="container">
                       
                        <div className="row">

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email</h4>
                                        <p><a href="mailto:info@tiger-sheep.com">info@tiger-sheep.com</a></p>
                                        <p><a href="mailto:support@tiger-sheep.com">support@tiger-sheep.com</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Mailing Address</h4>
                                        <p>Tiger Sheep, 4550 SW Betts Ave, PO Box 766<br /> Beaverton, OR 97005-2869</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                            {/* Start Single Address  */}
                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Phone</h4>
                                        <p><a href="tel:1-844-323-2673">1-844-323-2673</a><br/><a href="tel:1-844-323-2673">&nbsp;</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}


               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </React.Fragment>
        )
    }
}
export default Contact