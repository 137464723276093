import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import {TeamTS_support, TeamTS_admin, TeamTS_dev, TeamTS_pres} from "../../blocks/team/TeamTS";
import SliderOne from "../../component/slider/SliderOne";
import ServiceTwo from "../../elements/service/ServiceTwo";
import CounterOne from "../../elements/counters/CounterOne";
import Testimonial from "../../elements/Testimonial";
import About from "../../component/HomeLayout/homeOne/About";
import Portfolio from "../../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../../elements/blog/BlogContent";
import BrandTwo from "../../elements/BrandTwo";
import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../../elements/common/Breadcrumb";

class ChangeMe extends Component{
    render(){
        const PostList = BlogContent.slice(0 , 3);
		let title = 'About Us',
        description = "Tiger Sheep brings together a team of exceptional people to provide the smartest solutions for small and midsize businesses nationwide. We partner with companies like yours to guide the development of fantastic business applications, enabling maximum business growth and data intelligence. We're located in Beaverton, Oregon, and we love it here.";
        return(
            <Fragment> 
                
                <PageHelmet pageTitle='About Us' />
                <Header />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'About Us'}   />
                {/* End Breadcrump Area */}
                
                {/* Start About Area */}
				<main className="page-wrapper">
                <div className="about-area about-position-top pb--120">
                    <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-3 col-md-12">
                                <div className="thumbnail thumbnail_low ts_showyimage">
                                    <img className="w-100" src={process.env.PUBLIC_URL + "/assets/images/ts/ai-aboutus.jpg"} alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-9 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <p className="description">{description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
				<div className="bg_color--5">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="text-center">
									<h1 className="ts_section_title">Your Tiger Sheep Team</h1>
								</div>
							</div>
						</div>
					</div>
                </div>
				<div className="rn-team-wrapper ts_headline_justify ptb--60 bg_color--5">
                    <div className="rn-team-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="text-center mb--60">
                                        <h2>Admin</h2>
										<p>Tiger Sheep was founded by John-Michael Davis, a nationally-recognized leader in SAP Business One support and consultancy. Technical leadership permeates through the culture of Tiger Sheep, guiding the rest of the team to build exceptional tools for our clients.
										</p>
										<p>We're here to educate and consult! Our admin team connects you with the right staff and helps ensure our projects finish on time and on budget. Tiger Sheep understands the importance of building relationships with trusted partners, and together we work to help you accelerate your business.</p> 
                                    </div>
                                </div>
								<div className="col-lg-6">
									<TeamTS_admin column="col-lg-6 ts_portfoliospread" teamStyle="" item="12" />
								</div>
                            </div>
                        </div>
                    </div>
                </div>
				<div className="rn-team-wrapper ts_headline_justify ptb--60 bg_color--1">
                    <div className="rn-team-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--60">
                                        <h2>SAP B1 Support & Reporting</h2>
                                        <p>At Tiger Sheep, we pride ourselves in exceptional ongoing support. Our support team brings decades of experience to responsively and creatively help your staff solve problems. <br/>Our experienced reports team works to build fast & beautiful reports to give you the business data you need.</p>
                                    </div>
                                </div>
                            </div>
                            <TeamTS_support column="col-lg-3 ts_portfoliospread" teamStyle="" item="12" />
                        </div>
                    </div>
                </div>
				<div className="rn-team-wrapper ts_headline_justify ptb--60 bg_color--5">
                    <div className="rn-team-area">
                        <div className="container">
                            <div className="row ts_row_center">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--60">
                                        <h2>Development</h2>
                                        <p>Business logic is incredibly complex, and our team of developers builds powerful applications that run smarter and faster than they have any right to. A wide variety of disciplines and expertise come together to keep our work ahead of the competition. Our developers subvert your expectations of what business applications should look and feel like, injecting beauty and simplicity into all of our projects. Software doesn't have to be bulky and overcomplex, and our developer team works to help make sure everything we build is easy to understand, as simple as possible, and pleasant to use.</p>
                                    </div>
                                </div>
                            </div>
                            <TeamTS_dev column="col-lg-3 ts_portfoliospread" teamStyle="" item="12" />
                        </div>
                    </div>
                </div>
				<div className="rn-team-wrapper ptb--60 bg_color--1">
                    
                </div>
				</main>
                {/* End About Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
            </Fragment>
        )
    }
}
export default ChangeMe;