import React from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";

let dataTS_pres = [
    {
        images: 'jmdavis.jpg',
        title: 'John-Michael Davis',
        designation: 'President',
        socialNetwork: [
        ]
    },
	{
        images: 'srobinson.jpg',
        title: 'Steven Robinson',
        designation: 'Director / HR Manager',
        socialNetwork: [
        ]
    },
];

let dataTS_admin = [
    {
        images: 'jmdavis.png',
        title: 'John-Michael Davis',
        designation: 'President',
        socialNetwork: [
        ]
    },
	{
        images: 'srobinson.png',
        title: 'Steven Robinson',
        designation: 'Director / Project Manager',
        socialNetwork: [
        ]
    },
];

let dataTS_support = [
    {
        images: 'not-found.png',
        title: 'Kristy Crosby',
        designation: 'SAP Implementation Specialist',
        socialNetwork: [
        ]
    },
	{
        images: 'not-found.png',
        title: 'Matt Newell',
        designation: 'Software Architect',
        socialNetwork: [
        ]
    },
    {
        images: 'not-found.png',
        title: 'Seth Stager',
        designation: 'Reporting Specialist',
        socialNetwork: [
        ]
    },
    {
        images: 'not-found.png',
        title: 'Glen Thonis',
        designation: 'SAP Support Specialist',
        socialNetwork: [
        ]
    },
];

let dataTS_dev = [
    
	{
        images: 'not-found.png',
        title: 'Jennifer Dai',
        designation: 'Developer',
        socialNetwork: [
        ]
    },
	{
        images: 'not-found.png',
        title: 'Michael Meritt',
        designation: 'Lead Developer',
        socialNetwork: [
        ]
    },
    {
        images: 'not-found.png',
        title: 'Tina Ramsey',
        designation: 'Lead Developer',
        socialNetwork: [
        ]
    },
	{
        images: 'not-found.png',
        title: 'Cindy Truong',
        designation: 'Developer',
        socialNetwork: [
        ]
    },
];

export {dataTS_support, dataTS_admin, dataTS_dev, dataTS_pres};