import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import SliderOne from "../../component/slider/SliderOne";
import ServiceTwo from "../../elements/service/ServiceTwo";
import CounterOne from "../../elements/counters/CounterOne";
import Testimonial from "../../elements/Testimonial";
import About from "../../component/HomeLayout/homeOne/About";
import Portfolio from "../../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../../elements/blog/BlogContent";
import BrandTwo from "../../elements/BrandTwo";
import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../../elements/common/Breadcrumb";

class ChangeMe extends Component{
    render(){
        const PostList = BlogContent.slice(0 , 3);
		let title = 'Services',
        description = 'This is a Services paragraph! Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet';
        return(
            <Fragment> 
                
                <PageHelmet pageTitle='Services' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Services'}   />
                {/* End Breadcrump Area */}
                
                {/* Start About Area */}
                <div className="about-area about-position-top pb--120">
                    <div className="about-wrapper">
						<div className="container">
						
						
							<div id="support" className="ts_list_style row row--35 align-items-center">
								<div className="col-lg-5 col-md-12">
									<div className="">
										<img className="w-100 ts_showyimage" src={process.env.PUBLIC_URL + "/assets/images/ts/ai-partnership.jpg"} alt="About Images"/>
									</div>
								</div>
								<div className="col-lg-7 col-md-12">
									<div className="about-inner inner">
										<div className="section-title">
											<h2 className="title">Support</h2>
											<p className="description">
												<p>Tiger Sheep is nationally recognized for providing some of the best extended software support for SAP Business One in the country. As an SAP Business One Partner, Tiger Sheep is available for <span className="italics">&agrave; la carte</span> support for your CRM needs. </p>
												<p>Tiger Sheep is able to work with you to solve your issues in the best way to most closely match your process needs.</p>
												<p>Tiger Sheep's expertise extends well beyond SAP Business One, though. From reporting issues to workflow overhauls, call Tiger sheep when your business needs a fixer for any longstanding business data issue!</p>
											</p>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="col-lg-6 col-md-12 col-sm-12 col-12">
												<div className="about-us-list">
													<h3 className="title">Intelligent Upgrades</h3>
													<p>Sometimes the problem can't be solved with your current software. Tiger Sheep has the modern knowledge to suggest the right solution to augment your current toolkit.</p>
												</div>
											</div>
											<div className="col-lg-6 col-md-12 col-sm-12 col-12">
												<div className="about-us-list">
													<h3 className="title">Responsive Support</h3>
													<p>Whether over the phone, a screenshare, or via an on-site visit, we work to get your solution to you.</p>
													<img className="w-100" src={process.env.PUBLIC_URL + "/assets/images/logos/sapPartnerLogo.png"} alt="SAP Partner Logo Images"/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<div id="consultancy" className="ts_list_style row row--35 align-items-center">
								<div className="col-lg-5 col-md-12">
									<div className="">
										<img className="w-100 ts_showyimage" src={process.env.PUBLIC_URL + "/assets/images/ts/ai-support.jpg"} alt="About Images"/>
									</div>
								</div>
								<div className="col-lg-7 col-md-12">
									<div className="about-inner inner">
										<div className="section-title">
											<h2 className="title">Consultancy</h2>
											<p className="description">
												<p>No matter your business needs, Tiger Sheep is available to suggest your company's next step! We start with your processes and data, and work with you to arrive at the solution best matching your company's needs. Even if you're just looking for a second opinion about where to go, Tiger Sheep has the expertise to get your business pointed in the right direction.</p>
											</p>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="col-lg-6 col-md-12 col-sm-12 col-12">
												<div className="about-us-list">
													<h3 className="title">On-location Discovery</h3>
													<p>Let us come to you to discover the core of your process issues!</p>
												</div>
											</div>
											<div className="col-lg-6 col-md-12 col-sm-12 col-12">
												<div className="about-us-list">
													<h3 className="title">Process-First Solutions</h3>
													<p>We work to solve the problem in the way that matches your business best, not your software.</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							
							
							<div id="partnerships" className="ts_list_style row row--35 align-items-center">
								<div className="col-lg-5 col-md-12">
									<div className="">
										<img className="w-100 ts_showyimage" src={process.env.PUBLIC_URL + "/assets/images/ts/ai-consultancy.jpg"} alt="About Images"/>
									</div>
								</div>
								<div className="col-lg-7 col-md-12">
									<div className="about-inner inner">
										<div className="section-title">
											<h2 className="title">Partnerships</h2>
											<p className="description">
												<p>Tiger Sheep is always looking for power partners! Do you have a solution, but you're losing clientele due to a development gap? Are you looking to bundle powerful workflows with your current software offer? Are you a talented sales partner, looking to add to the value you provide to your clients? Give us a call!</p>
												<p>Tiger Sheep works with many companies across the country to provide extra value and increase brain trust. Up your company's game by partnering with us, or use us for the training your clients need!</p>
											</p>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="col-lg-6 col-md-12 col-sm-12 col-12">
												<div className="about-us-list">
													<h3 className="title">Unobtrusive Partnering</h3>
													<p>Your clients are your clients, and Tiger Sheep can offer them more value with some of our custom solutions and support options!</p>
												</div>
											</div>
											<div className="col-lg-6 col-md-12 col-sm-12 col-12">
												<div className="about-us-list">
													<h3 className="title">Quality Training</h3>
													<p>Either in person or via one of our training products, ease your clients into their new software with Tiger Sheep training!</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
												
							
						</div>
					</div>
                </div>
                {/* End About Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
            </Fragment>
        )
    }
}
export default ChangeMe;