import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import SliderOne from "../../component/slider/SliderOne";
import ServiceTwo from "../../elements/service/ServiceTwo";
import CounterOne from "../../elements/counters/CounterOne";
import Testimonial from "../../elements/Testimonial";
import About from "../../component/HomeLayout/homeOne/About";
import Portfolio from "../../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../../elements/blog/BlogContent";
import BrandTwo from "../../elements/BrandTwo";
import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../../elements/common/Breadcrumb";

class ChangeMe extends Component{
    render(){
        const PostList = BlogContent.slice(0 , 3);
		let title = 'What We Do',
        description = 'This is a What We Do paragraph! Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet';
        return(
            <Fragment> 
                
                <PageHelmet pageTitle='What We Do' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'What We Do'}   />
                {/* End Breadcrump Area */}
                
                {/* Start About Area */}
                <div className="about-area about-position-top pb--120">
                    <div className="about-wrapper">
                    <div className="container">
					
					
                        <div id="workflowReplacement" className="ts_list_style row row--35 align-items-center">
                            <div className="col-lg-4 col-md-12">
                                <div className="">
                                    <img className="w-100 ts_showyimage" src={process.env.PUBLIC_URL + "/assets/images/ts/ai-workflow.jpg"} alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Workflow Replacement</h2>
                                        <p className="description">
											<p>Tiger Sheep's primary specialty is workflow replacement, where we consult with you to <span className="uk-text-bold">build the business software you've always wanted.</span> Instead of letting your current software control your process, we build new applications and integrations to get your system to match exactly what you're looking for. Power and usability often seem like they are mutually exclusive, but not with Tiger Sheep! </p>
											<p>All of our custom solutions use modern user experience practices to let your employees do their jobs faster and with less training. <span className="uk-text-bold">Let your company grow to the level you know it can reach!</span></p>
										</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
										<div className="about-us-list">
											<p>
												<h3 className="title">Some Examples:</h3>
												<ul>
													<li>For a high-turnover sales force, we designed an application that let users interact with a custom frontend while the data writes back directly to the administration backend. Call times were significantly improved, and training time was reduced drastically.</li>
													<li>A high-density customer service organization needed an improved Work Order entry system, and their current technology was limiting their speed of entry. Without disrupting their current training significantly, our solution enabled speed without sacrificing client insight.</li>
													<li>Skilled technicians for a large repair organization were lacking a speedy method to state and track their work. An application was developed to let their entry process go faster, while allowing their notes to be more verbose without complicating entry.</li>
												</ul>
											</p>
										</div>
								  
                                    </div>
                                </div>
                            </div>
                        </div>
						
						<div id="dataIntelligence" className="ts_list_style row row--35 align-items-center">
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Data Intelligence</h2>
										<p className="description">
											<p>Data Intelligence is a largely overused or misused phrase that has become popular in recent years. Tiger Sheep sticks to the true value of processes and data analysis, so when we talk about Data Intelligence we truly mean intelligence. Custom workflows are structured to capture data in a clean and consistent manner, and make smart decisions for your organization. We help you extend your business into new markets or better tune your existing processes to be more efficient and profitable. </p>
											<p>Through the use of SAP Business One's core data structure and Tiger Sheep's custom workflow applications, we help businesses capture the data that is needed to drive smart decisions. We design the processes and entry methodologies that are tailored to your specific business needs, and advise on scenarios we have helped other customers with in the past. <span className="uk-text-bold">Stepping forward with Tiger Sheep is moving your business forward with confidence. </span></p>
											
											</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12">
                                <div className="">
                                    <img className="w-100 ts_showyimage" src={process.env.PUBLIC_URL + "/assets/images/ts/ai-dataintelligence.jpg"} alt="About Images"/>
                                </div>
                            </div>
                        </div>
						
						
                        <div id="eCommerce" className="ts_list_style row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="">
                                    <img className="w-100 ts_showyimage" src={process.env.PUBLIC_URL + "/assets/images/ts/ai-ecom.jpg"} alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">eCommerce</h2>
                                        <p className="description">
											<p>Our eCommerce sites and integrations are used by thousands of users every day! We connect powerful and secure eCommerce platforms such as WooCommerce or Magento to your system, automating most of the hassle work involved with running a web shop. Automatic inventory updates, email triggers, and payment integrations: We let you make the most money from your eCommerce site!</p>
										</p>
                                    </div>
                                </div>
                            </div>
                        </div>
						
						<div id="softwareAutomation" className="ts_list_style row row--35 align-items-center">
                            <div className="col-lg-8 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Software Automation</h2>
										<p className="description">
											<p>Tiger Sheep has developed an incredible engine, called Automat, for making connections between different software. Automat enables custom automations, activated by an huge list of possible business logic triggers. Some Tiger Sheep automations currently easing life for our clients include (ready to get technical?):</p>
											<ul className="uk-text-bold about-us-list">
												<li>The ability to generate full ERP service calls based on external input</li>
												<li>Import and update ERP documents based on XML, CSV, or Excel filmnjes</li>
												<li>Mid-process alterations & conversions to imported documents</li>
												<li>Migrate a document between different document types and even different databases</li>
												<li>Send templated emails based on logical triggers, allowing for smarter marketing responses</li>
												<li>Update document status or individual fields based on external triggers like form submission</li>
												<li>FTP-upload response, triggering document imports and conversions</li>
												<li>Document export into a multitude of file formats, including industry-specific formats</li>
												<li>eCommerce import and export, updating inventory and customer details</li>
												<li>Form submissions writing to multiple tables in multiple databases</li>
											</ul>
											<p>These are just a small variety of our current and possible automations, and any number of these can be mixed together to fix your workflow issues.</p>
										</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="">
                                    <img className="w-100 ts_showyimage" src={process.env.PUBLIC_URL + "/assets/images/ts/ai-automation.jpg"} alt="About Images"/>
                                </div>
                            </div>
                        </div>
						
						<div id="thirdPartyIntegrations" className="ts_list_style row row--35 align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Third-Party Integrations</h2>
                                        <p className="description">
											<p>We integrate with and use a diverse set of third-party software, and our list is constantly growing. The software you're used to using can often be interacted with in surprising ways! Whether directly or externally, Tiger Sheep has tools capable of a wide array of data automation and manipulation techniques. </p>
										</p>
										<div className="row mt--30 mt_sm--10">
											<p className="about-us-list">
												<h3 className="title">Some software we integrate with:</h3>
											</p>
										</div>
										<div className="row">
											<div className="ts_logo_grid">
												<div class="ts_logo_grid_item">
													<img src={process.env.PUBLIC_URL + "/assets/images/logos/SAPB1Logo.gif"}></img>
												</div>
												<div class="ts_logo_grid_item">
													<img src={process.env.PUBLIC_URL + "/assets/images/logos/formsiteLogo.gif"}></img>
												</div>
												<div class="ts_logo_grid_item">
													<img src={process.env.PUBLIC_URL + "/assets/images/logos/crystalLogo.gif"}></img>
												</div>
												<div class="ts_logo_grid_item">
													<img src={process.env.PUBLIC_URL + "/assets/images/logos/magentoLogo.gif"}></img>
												</div>
												<div class="ts_logo_grid_item">
													<img src={process.env.PUBLIC_URL + "/assets/images/logos/enpriseLogo.gif"}></img>
												</div>
												<div class="ts_logo_grid_item">
													<img src={process.env.PUBLIC_URL + "/assets/images/logos/excelLogo.gif"}></img>
												</div>
											</div>
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>
						
                    </div>
                </div>
                </div>
                {/* End About Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
            </Fragment>
        )
    }
}
export default ChangeMe;