import React from 'react'
import {dataTS_admin, dataTS_support, dataTS_dev, dataTS_pres} from "./dataTS";

const TeamTS_pres = (props) => {
    const itemSlice = dataTS_pres.slice(0 , props.item)
    return (
        <div className="row">
            {itemSlice.map((value , i ) => (
                <div className={`${props.column}`} key={i}>
                    <div className={`team-static ${props.teamStyle}`}>
                        <div className="thumbnail">
							<span>
								<img src={process.env.PUBLIC_URL + `/assets/images/team/team-${value.images}`} alt="Team Member"/>
							</span>
                        </div>
                        <div className="inner">
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                                <p className="designation">{value.designation}</p>
                            </div>
                            {/*<ul className="social-transparent liststyle d-flex" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                )}
                            </ul>*/}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

const TeamTS_admin = (props) => {
    const itemSlice = dataTS_admin.slice(0 , props.item)
    return (
        <div className="row">
            {itemSlice.map((value , i ) => (
                <div className={`${props.column}`} key={i}>
                    <div className={`team-static ${props.teamStyle}`}>
                        <div className="thumbnail">
                            <span>
								<img src={process.env.PUBLIC_URL + `/assets/images/team/team-${value.images}`} alt="Team Member"/>
							</span>
                        </div>
                        <div className="inner">
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                                <p className="designation">{value.designation}</p>
                            </div>
                            {/*<ul className="social-transparent liststyle d-flex" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                )}
                            </ul>*/}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

const TeamTS_support = (props) => {
    const itemSlice = dataTS_support.slice(0 , props.item)
    return (
        <div className="row">
            {itemSlice.map((value , i ) => (
                <div className={`${props.column}`} key={i}>
                    <div className={`team-static ${props.teamStyle}`}>
                        <div className="thumbnail">
                            <span>
								<img src={process.env.PUBLIC_URL + `/assets/images/team/team-${value.images}`} alt="Team Member"/>
							</span>
                        </div>
                        <div className="inner">
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                                <p className="designation">{value.designation}</p>
                            </div>
                            {/*<ul className="social-transparent liststyle d-flex" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                )}
                            </ul>*/}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

const TeamTS_dev = (props) => {
    const itemSlice = dataTS_dev.slice(0 , props.item)
    return (
        <div className="row">
            {itemSlice.map((value , i ) => (
                <div className={`${props.column}`} key={i}>
                    <div className={`team-static ${props.teamStyle}`}>
                        <div className="thumbnail">
							<span>
								<img src={process.env.PUBLIC_URL + `/assets/images/team/team-${value.images}`} alt="Team Member"/>
							</span>
                        </div>
                        <div className="inner">
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                                <p className="designation">{value.designation}</p>
                            </div>
                            {/*<ul className="social-transparent liststyle d-flex" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                )}
                            </ul>*/}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}
export {TeamTS_support, TeamTS_admin, TeamTS_dev, TeamTS_pres};
