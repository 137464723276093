import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} | Tiger Sheep LLC </title>
                    <meta name="description" content="The creativity of a small team of experts
The stability of decades of experience" />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
