import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import SliderOne from "../../component/slider/SliderOne";
import ServiceTwo from "../../elements/service/ServiceTwo";
import CounterOne from "../../elements/counters/CounterOne";
import Testimonial from "../../elements/Testimonial";
import About from "../../component/HomeLayout/homeOne/About";
import Portfolio from "../../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../../elements/blog/BlogContent";
import BrandTwo from "../../elements/BrandTwo";
import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../../elements/common/Breadcrumb";

class ChangeMe extends Component{
    render(){
        const PostList = BlogContent.slice(0 , 3);
		let title = 'Careers',
        description = 'This is a Careers paragraph! Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet';
        return(
            <Fragment> 
                
                <PageHelmet pageTitle='Careers' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Careers'}   />
                {/* End Breadcrump Area */}
                
                {/* Start About Area */}
                <div className="about-area about-position-top pb--120">
                    <div className="about-wrapper">
                    <div className="container">
                        <div className="ts_list_style row row--35">

								<div id="systemsadmin" className="ts_job_listing col-lg-12 col-md-12">
									<div className="about-inner inner">
										<div className="section-title about-us-list">
											<h2 className="title">Systems Administrator</h2>
											<p className="description">
												<h3 className="title">Job Basics</h3>
												<p>We are looking for an experienced and dedicated Systems Administrator to join our team. This position is remote, with an optional local Portland/Beaverton office should you want the space. The ideal candidate will have a strong technical background and be able to take charge of our company (and our client’s) technical infrastructure, ensuring smooth operation and ongoing maintenance. We want to empower this role to bridge the line between ensuring data protection and empowering coworkers to work quickly and creatively.</p>
												<p>Base pay is varied based on experience, valuing direct relevant experience. We offer a health insurance stipend for all full-time workers after three months.</p>
											</p>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>
													<h3 className="title">Responsibilities</h3>
													<ul>
														<li>Install and maintain Windows Server 2019 and 2022</li>
														<li>Install and maintain SAP Business One and its paired technologies</li>
														<li>Install and maintain SQL2019</li>
														<li>Install and maintain NGINX</li>
														<li>Work with a wide array of lightly-used technologies to ensure best IT and SA practices and creatively problem-solve</li>
													</ul>
												</p>
											</div>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>
													<h3 className="title">Requirements</h3>
													<ul>
														<li>Strong experience in Windows Server management</li>
														<li>Experience with Windows Group Policy enforcement</li>
														<li>Cloud computing experience, specifically with AWS</li>
														<li>Knowledge of SQL2019 maintenance task management</li>
														<li>Basic knowledge of Linux (for NGINX servers)</li>
														<li>Experience with Git</li>
													</ul>
												</p>
												<p>
													<b>Please do not apply if you do not meet all of the above requirements.</b>
												</p>
											</div>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>
													<h3 className="title">Bonus Great-To-Haves</h3>
													<ul>
														<li>Experience with ERP software such as SAP Business One</li>
														<li>A degree or relevant certifications</li>
													</ul>
												</p>
											</div>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>		
													<h3 className="title">Desired Traits</h3>	
													<ul>	
														<li>Creative problem solver with a proactive approach</li>
														<li>Mind for security and sensitivity to client data</li>
														<li>Sense of urgency for time-sensitive requests</li>
														<li>Forward thinker, with the ability to consider future needs and solutions</li>
													</ul>
												</p>
											</div>
										</div><div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>If you are a motivated and skilled Systems Administrator, with a passion for technology and a strong commitment to your work, we encourage you to apply with a resume and cover letter. Base pay will vary based on experience. We offer a health insurance stipend for all full-time workers after three months.</p>
											</div>
										</div>
									</div>
								</div>

								<div id="backenddeveloper" className="ts_job_listing col-lg-12 col-md-12">
									<div className="about-inner inner">
										<div className="section-title about-us-list">
											<h2 className="title">Backend Developer (NodeJS)</h2>
											<p className="description">
												<h3 className="title">Job Basics</h3>
												<p>We're looking for 1-2 full-time remote-optional web developers, mostly for backend work. Successful applicants will be excited to use modern webapp development practices to build quality business solutions for clients. Our office is mostly remote, but applicants in the Portland, Oregon area have a home base to work from should they like to.</p>
												<p>We're hiring to replace a highly-valued long-lasting coworker who left us for a much larger company. To that end, we have an existing batch of projects that occasionally need support, and existing key clients who we want to quickly train quality developers to be able to work directly with, with the support of the rest of the team. We are not looking for entry-level developers at this time.</p>
												<p>Base pay is varied based on experience, valuing direct relevant experience. We offer a health insurance stipend for all full-time workers after three months.</p>
											</p>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>
													<h3 className="title">Responsibilities</h3>
													<ul>
														<li>Respond to a wide variety of programming problems with creative solutions - We have a decade of projects behind us, and occasionally we need to break open an old project to make small updates for our clients.</li>
														<li>Comfortably lead single-developer projects - When we're able to scope a project down to a single developer's purview, we prefer to. Please do not apply if you are not comfortable taking on projects on your own.</li>
														<li>Work well both alone and with teams - It's relatively rare for us that an individual project requires multiple developers, but it does happen!</li>
														<li>Effectively multitask and prioritize between small and large tasks - Our best employees tend to be our most-organized employees.</li>
														<li>Continually and actively increase your knowledge of our entire web stack, independent of your current project - Other developers may be working with an entirely different stack from your current project's, and keeping abreast of each other's work is crucial.</li>
														<li>Influence the direction of future projects based on your personal tech preferences and observations - We like to think that we allow for the 'best' solutions to problems even if it means a change in technology used. If you have strong opinions about a solution and can back it up, that's valuable.</li>
													</ul>
												</p>
											</div>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>
													<h3 className="title">Requirements</h3>
													<ul>
														<b>Frontend:</b>
														<li>!!! Strong React + Redux knowledge</li>
														<li>!! HTML/CSS knowledge</li>
														<li>!! Familiarity with consuming REST APIs</li>
														<b>Backend:</b><li>!!! Deep Node.js experience</li>
														<li>!!! Experience with SQL (MSSQL/PostgreSQL) for development</li>
														<li>!! REST API creation and function experience</li>
														<li>!! Understanding of web security (JWTs, XSRF, CSRF, XSS, etc)</li>
														<b>Other/Devops:</b><li>    !!! Nginx Proxy configuration experience</li>
														<li>!!! Strong troubleshooting skills</li>
														<li>!! Basic Networking (Opening ports, VPN access, etc) experience</li>
														<li>! Familiarity with Windows Server and MSSQL installation and configuration</li>
														<li>! Understanding of web security (specifically knowledge of how to work with SSL/TLS certificates)</li>
														<li>! An active, ongoing passion for web and/or software development.</li>
														<li>! English fluency, and the legal ability to work in the US - We are absolutely not taking applications from outside of the USA at this time.</li>
														<b>Please do not apply if you do not meet all of the above requirements.</b>
													</ul>
												</p>
											</div>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>
													<h3 className="title">Bonus Great-To-Haves</h3>
													<ul>
														<li>!!! Experience with SAP Business One - 90% of our projects interface with SAP Business One in some way. Experience with it would be a huge boost to any qualified candidate.</li>
														<li>!!! Experience with any major ERP system - SAP B1 aside, knowledge with the process and function of any major ERP system is valuable.</li>
														<li>!!! Accounting knowledge/vocabulary - Likewise, a familiarity with accounting systems or processes consistently proves valuable for our developers.</li>
														<li>!! Experience with CI/CD systems - We'd love to adopt faster practices!</li>
														<li>! Located in Oregon. - We're happy to hire fully-remote workers and have no plans to return to office-only work, but we also like to occasionally see our coworkers in person.</li>
													</ul>
												</p>
											</div>
										</div><div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>Base pay will vary based on experience. We offer a health insurance stipend for all full-time workers after three months.</p>
											</div>
										</div>
									</div>
								</div>
								
								<div id="supportspecialist" className=" ts_job_listing col-lg-12 col-md-12">
									<div className="about-inner inner">
										<div className="section-title about-us-list">
											<h2 className="title">Support Specialist</h2>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>
													<h3 className="title">Responsibilities</h3>
													<ul>
														<li>Take ownership over the support and maintenance of the SAP Business One installations of a varied set of clients.</li>
														<li>Be a friendly support contact, no matter the client personality</li>
														<li>Work well in a team-based environment.</li>
														<li>Multi-task and prioritize effectively against a varied list of support issues.</li>
														<li>Communicate effectively both digitally and in person.</li>
														<li>Continually and actively increase your knowledge of our work, and SAP Business One's specific quirks</li>
													</ul>
												</p>
											</div>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>
													<h3 className="title">Requirements</h3>
													<ul>
														<li>A working knowledge of enterprise resource planning software.</li>
														<li>An understanding of accounting systems.</li>
														<li>Experience in a business software support role.</li>
														<li>A technical mind, and a willingness to learn the quirks of our client's software.</li>
														<li>English fluency, and the legal ability to work in the US.</li>
														<li>A willingness and excitement to work directly with a varied set of clients.</li>
													</ul>
												</p>
											</div>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>
													<h3 className="title">Bonus Great-To-Haves</h3>
													<ul>
														<li>SQL query writing experience, or a proven ability to learn quickly with similar language experience</li>
														<li>Any specific experience with SAP Business One, SQL Server, or any of the similar ERP solutions.</li>
														<li>A familiarity with the creation and maintenance of views and stored procedures</li>
														<li>A relevant Business/Accounting/Computer Science degree</li>
													</ul>
												</p>
											</div>
										</div><div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>Base pay will vary based on experience. We offer a health insurance stipend for all full-time workers after three months.</p>
											</div>
										</div>
									</div>
								</div>
								
								<div id="reportspecialist" className=" ts_job_listing col-lg-12 col-md-12">
									<div className="about-inner inner">
										<div className="section-title about-us-list">
											<h2 className="title">Reports Specialist</h2>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>
													<h3 className="title">Responsibilities</h3>
													<ul>
														<li>Take ownership over writing and designing quality Crystal Reports, for a varied set of clients.</li>
														<li>Understand the use of queries in financial / business software applications.</li>
														<li>Work well in a team-based environment, with a variety of personalities.</li>
														<li>Communicate effectively both digitally and in person.</li>
														<li>Continually and actively increase your knowledge of our work, independent of your current projects.</li>
													</ul>
												</p>
											</div>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>
													<h3 className="title">Requirements</h3>
													<ul>
														<li>A working knowledge of accounting systems.</li>
														<li>2+ years of report writing (Crystal Reports specifically is a HUGE plus) experience.</li>
														<li>2+ years of SQL query writing experience, or a proven ability to learn quickly with similar language experience.</li>
														<li>A familiarity with the creation and maintenance of views and stored procedures.</li>
														<li>A technical mind, and a willingness to learn the quirks of our client's software.</li>
														<li>English fluency, and the legal ability to work in the US.</li>
													</ul>
												</p>
											</div>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>
													<h3 className="title">Bonus Great-To-Haves</h3>
													<ul>
														<li>Any experience with SAP Business One, SQL Server, or any of the similar ERP solutions.</li>
														<li>A relevant Business/Accounting degree.</li>
													</ul>
												</p>
											</div>
										</div><div className="row mt--30 mt_sm--10">
											<div className="about-us-list">
												<p>Base pay will vary based on experience. We offer a health insurance stipend for all full-time workers after three months.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						<div className="row ts_text_center_force">
							<h3 className="">Contact info@tiger-sheep.com for more information</h3>
							<p>We are always taking resumes for outstanding individuals. Are you a talented developer looking for a career change? Are you a web designer with a strong portfolio looking to contribute to a growing team? Do you have SAP Business One experience, and want to help support a strong group of clients? We keep an active resume file, and our hiring rounds always reference our current known pool.</p>
						</div>
						</div>
					</div>
                </div>
                {/* End About Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
            </Fragment>
        )
    }
}
export default ChangeMe;