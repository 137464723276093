import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import SliderOne from "../../component/slider/SliderOne";
import ServiceTwo from "../../elements/service/ServiceTwo";
import CounterOne from "../../elements/counters/CounterOne";
import Testimonial from "../../elements/Testimonial";
import About from "../../component/HomeLayout/homeOne/About";
import Portfolio from "../../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../../elements/blog/BlogContent";
import BrandTwo from "../../elements/BrandTwo";
import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../../elements/common/Breadcrumb";

class ChangeMe extends Component{
    render(){
        const PostList = BlogContent.slice(0 , 3);
		let title = 'Blog Coming Soon',
        description = 'The Tiger Sheep blog is under maintenance, but will be back soon.';
        return(
            <Fragment> 
                
                <PageHelmet pageTitle='Blog' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Blog'}   />
                {/* End Breadcrump Area */}
                
                {/* Start About Area */}
                <div className="about-area about-position-top pb--120">
                    <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">


                            <div className="col-lg-8 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="thumbnail ts_showyimage thumbnail_low">
                                    <img className="w-100" src={process.env.PUBLIC_URL + "/assets/images/ts/ai-blog.jpg"} alt="About Images"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                {/* End About Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
            </Fragment>
        )
    }
}
export default ChangeMe;