import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";


class HeaderMenuStructure extends Component{
	
	render(){    
		
        return(
			<ul className="mainmenu">
				{global.mode.current == "Prod" &&
					<>
						<li className=""><Link to="/">Home</Link>
						</li>
					</>
				}
				{global.mode.current == "Demo" &&
					<>
						<li className=""><Link to="/">Home</Link>
							<ul className="submenu">
								<li><Link to="/tshome">TS Homepage</Link></li>
								<li><Link to="/tshome-dark">TS Homepage (Dark)</Link></li>
								<li><Link to="/placeholder">Placeholder Page</Link></li>
							</ul>
						</li>
						<li className="has-droupdown mobileyes"><Link to="#">Home</Link>
							<ul className="submenu">
								<li><Link to="/tshome">TS Homepage</Link></li>
								<li><Link to="/tshome-dark">TS Homepage (Dark)</Link></li>
								<li><Link to="/placeholder">Placeholder Page</Link></li>
							</ul>
						</li>
					</>
				}
				<li className="has-droupdown"><Link to="/aboutus" >About Us</Link>
					<ul className="submenu">
						<li><Link to="/tsblog" >Blog</Link></li>
						<li><Link to="/ourwork" >Work Examples</Link></li>
						<li><Link to="/services" >Services</Link></li>
						<li><Link to="/whatwedo" >What We Do</Link></li>
					</ul>
				</li>
				<li className="has-droupdown mobileyes" ><Link to="#">About Us</Link>
					<ul className="submenu">
						<li className="mobileyes"><Link to="/aboutus" >About Us</Link></li>
						<li><Link to="/tsblog" >Blog</Link></li>
						<li><Link to="/ourwork" >Work Examples</Link></li>
						<li><Link to="/services" >Services</Link></li>
						<li><Link to="/whatwedo" >What We Do</Link></li>
					</ul>
				</li>
				<li><Link to="/careers" >Careers</Link></li>
				<li><Link to="/contact" >Contact</Link></li>
				{global.mode.current == "Demo" &&
					<>
						<li className="has-droupdown demo_color"><Link to="#">Demo</Link>
							<ul className="submenu">
								<li><Link to="/demo">Demo: Base</Link></li>
								<li><Link to="/main-demo">Demo: Main</Link></li>
								<li><Link to="/dark-main-demo">Demo: Main (Dark)</Link></li>
								<li><Link to="/creative-agency">Demo: Creative Agency</Link></li>
								<li><Link to="/creative-landing">Demo: Creative One Page</Link></li>
								<li><Link to="/creative-portfolio">Demo: Creative Portfolio</Link></li>
								<li><Link to="/personal-portfolio">Demo: Personal Portfolio</Link></li>
								<li><Link to="/portfolio-landing">Demo: Portfolio One Page</Link></li>
								<li><Link to="/dark-portfolio-landing">Demo: Portfolio One Page 02</Link></li>
								<li><Link to="/digital-agency">Demo: Digital Agency</Link></li>
								<li><Link to="/startup">Demo: Startup</Link></li>
								<li><Link to="/paralax">Demo: Paralax</Link></li>
								<li><Link to="/portfolio-home">Demo: Minimal Portfolio</Link></li>
								<li><Link to="/business">Demo: Business</Link></li>
								<li><Link to="/home-particles">Demo: Home Particles</Link></li>
								<li><Link to="/studio-agency">Demo: Studio Agency</Link></li>
								<li><Link to="/designer-portfolio">Demo: Designer Portfolio</Link></li>
								<li><Link to="/interactive-agency">Demo: Interactive Agency</Link></li>
							</ul>
						</li>
						<li className="has-droupdown demo_color"><Link to="/service" >Service</Link>
							<ul className="submenu">
								<li><Link to="/service">Service</Link></li>
								<li><Link to="/service-details">Service Details</Link></li>
							</ul>
						</li>
						<li className="demo_color"><Link to="/about" >About</Link></li>

						<li className="has-droupdown demo_color"><Link to="#pages" >Pages</Link>
							<ul className="submenu">
								<li><Link to="/blog">Blog List</Link></li>
								<li><Link to="/blog-details">Blog Details</Link></li>
								<li><Link to="/service">Service</Link></li>
								<li><Link to="/service-details">Service Details</Link></li>
								<li><Link to="/portfolio">Portfolio</Link></li>
								<li><Link to="/portfolio-details">Portfolio Details</Link></li>
								<li><Link to="/404">404</Link></li>
							</ul>
						</li>
						<li className="has-droupdown demo_color"><Link to="#" >Blocks</Link>
							<ul className="submenu">
								<li><Link to="/portfolio">Portfolio</Link></li>
								<li><Link to="/team">Team</Link></li>
								<li><Link to="/service">Service</Link></li>
								<li><Link to="/video-popup">Video Popup</Link></li>
								<li><Link to="/progressbar">Progressbar</Link></li>
								<li><Link to="/gallery">Gallery</Link></li>
								<li><Link to="/counters">Counters</Link></li>
								<li><Link to="/blog">Blog List</Link></li>
								<li><Link to="/clint-logo">Clint Logo</Link></li>
								<li><Link to="/contact-form">Contact Form</Link></li>
								<li><Link to="/google-map">Google Map</Link></li>
								<li><Link to="/columns">Columns</Link></li>
								<li><Link to="/pricing-table">Pricing Table</Link></li>
							</ul>
						</li>
						<li className="demo_color"><Link to="/contact" >Contact</Link></li>
					</>
				}
			</ul>
        )
    }
}
export default HeaderMenuStructure;