import React, { Component } from "react";
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn, FaYoutube} from "react-icons/fa";

const SocialShare = [
    {Social: <FaYoutube /> , link: 'https://www.facebook.com/TigerSheepLLC/'},
    {Social: <FaTwitter /> , link: 'https://mobile.twitter.com/tigersheepprime'},
]

class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Find your smarter solution</span>
                                        <h2>Make plans with Tiger Sheep</h2>
                                        <a className="rn-button-style--2" href={process.env.PUBLIC_URL + "/contact"}>
                                            <span>Contact Us Today</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
									<a className="mobileyes footermobileimage" href={process.env.PUBLIC_URL + "/"}>
										<img src={process.env.PUBLIC_URL + "/assets/images/logo/big-logo.png"} alt="Logo images"/>
									</a>
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                <h4>What We Do</h4>
                                                <ul className="ft-link">
                                                    <li><a href={process.env.PUBLIC_URL + "/whatwedo#workflowReplacement"}>Workflow Replacement</a></li>
                                                    <li><a href={process.env.PUBLIC_URL + "/whatwedo#dataIntelligencwe"}>Data Intelligence</a></li>
                                                    <li><a href={process.env.PUBLIC_URL + "/whatwedo#eCommerce"}>eCommerce</a></li>
                                                    <li><a href={process.env.PUBLIC_URL + "/whatwedo#softwareAutomation"}>Software Automation</a></li>
                                                    <li><a href={process.env.PUBLIC_URL + "/whatwedo#thirdPartyIntegrations"}>3rd-Party Integration</a></li>
                                                </ul>
                                            </div>
											<div className="footer-link">
                                                <h4>Work Examples</h4>
                                                <ul className="ft-link">
                                                    <li><a href={process.env.PUBLIC_URL + "/ourwork#ecommerce"}>eCommerce</a></li>
                                                    <li><a href={process.env.PUBLIC_URL + "/ourwork#pos"}>POS App</a></li>
                                                    <li><a href={process.env.PUBLIC_URL + "/ourwork#sales"}>Sales App</a></li>
                                                    <li><a href={process.env.PUBLIC_URL + "/ourwork#technician"}>Technician App</a></li>
                                                </ul>
                                            </div>
                                        </div>
										
										
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Quick Links</h4>
                                                <ul className="ft-link">
                                                    <li><a href={process.env.PUBLIC_URL + "/aboutus"}>About Us</a></li>
													<li><a href="https://docs.google.com/document/u/2/d/e/2PACX-1vSqUTd8V90eFqr6ixUX6uTn1SZ-BMW9UZIuXbqPemT_71Erqd-lerSbizjJRohqTzz162ZCLI7hYjVA/pub">Appendix</a></li>
													<li><a href={process.env.PUBLIC_URL + "/tsblog"}>Blog</a></li>
													<li><a href={process.env.PUBLIC_URL + "/careers"}>Careers</a></li>
													<li><a href={process.env.PUBLIC_URL + "/contact"}>Contact</a></li>
													<li><a href={process.env.PUBLIC_URL + "/services"}>Services</a></li>
                                                </ul>

                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div>
												<div className="ts_footer_image">
													<img src={process.env.PUBLIC_URL + "/assets/images/logo/sap-silver-partner-logo.png"} alt="Logo images"/>
												</div>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                    </div>
                                </div>
                            </div>
                        </div>
						<div 
							className="row footer_bottom"
							data-black-overlay="10"
							>
							<div className="col-lg-12">
								<div className="ts_footer_copyright">
									<p className="ts_footer_copyright_p_small">info@tiger-sheep.com</p><p className="ts_footer_copyright_spacer">     |     </p><p className="ts_footer_copyright_p_small">1-844-323-2673</p><p className="ts_footer_copyright_spacer">     |     </p><p>Mail: Tiger Sheep, 4550 SW Betts Ave, PO Box 766, Beaverton, OR 97005-2869</p><p className="ts_footer_copyright_spacer">     |     </p><p>Copyright © 2023 Tiger Sheep. All Rights Reserved.</p>
								</div>
							</div>
						</div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;